import { ref } from "vue";
import store from "@/store";

export const useEnableInterim = () => {
    const loading = ref(false);
    const enableInterim = async ({ workerId }: { workerId: string }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/changeInterimActivity", {
                interimId: workerId,
                status: "active",
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, enableInterim };
};
