var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"MapView"},[_c(_setup.LMap,{ref:"map",staticClass:"map",attrs:{"center":_setup.center,"zoom":_vm.zoom,"options":{
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            clickableIcons: false,
        }}},[_c(_setup.LTileLayer,{attrs:{"url":_setup.url,"attribution":_setup.attribution}}),_c('v-marker-cluster',_vm._l((_vm.rows),function(row,index){return _c(_setup.LMarker,{key:index,attrs:{"lat-lng":_setup.latLng(row)}},[_c(_setup.LPopup,[_c('link-like',{on:{"click":function($event){_setup.goTo(row._id.toString())}}},[_vm._v(_vm._s(row.name))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }