import { ref } from "vue";
import store from "@/store";

export const useDisableInterim = () => {
    const loading = ref(false);
    const disableInterim = async ({ workerId, data }: { workerId: string; data: { reason: string; reasonComplement: string } }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/changeInterimActivity", {
                interimId: workerId,
                status: "inactive",
                reason: data.reason,
                reasonComplement: data.reasonComplement,
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, disableInterim };
};
