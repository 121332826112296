import { ref } from "vue";
import store from "@/store";
import { CandidateCommand, MeetingResult } from "@/data";
import { EValidationStatus, IInterviewDB } from "@/types";

interface IData {
    name: string;
    interview: IInterviewDB | null;
    comment: string;
    meetingResult: MeetingResult;
    MeetingResult: MeetingResult;
    error: null;
    emailValidity?: EValidationStatus;
    phoneValidity?: EValidationStatus;
}

export const useCandidateMeetingResult = () => {
    const loading = ref(false);
    const submitMeetingResult = async ({ workerId, data }: { workerId: string; data: IData }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/sendCommandCandidate", {
                candidateId: workerId,
                command: CandidateCommand.meetingResult,
                data: {
                    interview: data?.interview?._id,
                    comment: data.comment,
                    meetingResult: data.meetingResult,
                    emailValidity: data.emailValidity,
                    phoneValidity: data.phoneValidity,
                },
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, submitMeetingResult };
};
