
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import ActionButton from "@/components/ActionButton.vue";
import NewIcon from "@/components/NewIcon.vue";
import Tag from "@/components/Tag.vue";

@Component({
    name: "ToMeetModal",
    components: { Tag, NewIcon, ActionButton, CandidateProfileDisplay },
})
export default class ToMeetModal extends mixins(CandidateModalCommandMixin) {
    name = "ToMeetModal";
    comment = "";
}
