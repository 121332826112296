
import {Component, Vue, Prop} from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";
import {ROUTES} from "@/data";
import {capitalize} from "@/helpers/commons";
import { Getter } from 'vuex-class';

@Component({
    name: "InterimTile",
    components: {Tile}
})
export default class InterimTile extends Vue {
    @Prop() interim!: any;

    @Getter('isSupervisor') isSupervisor!: boolean;

    apiUrl:string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    get picture() {
        return this.apiUrl + this.interim.picture;
    }

    get jobNames() {
        return (this.interim?.jobNames || []).filter((a:any)=>a).map(capitalize).join(', ');
    }

    get hasLocation() {
        return this.interim.geolocated;
    }

    goToInterim() {
        this.$router.push({name: ROUTES.APP.INTERIM._ROOT, params: {interimId: this.interim._id}});
    }
}
