import { ref } from "vue";
import store from "@/store";

export const useVerifyEmailAndPhone = () => {
    const loading = ref(false);
    const verifyEmailAndPhone = async ({ workerEmail, workerPhone }: { workerEmail?: string; workerPhone?: string }) => {
        try {
            loading.value = true;
            if (!workerEmail && !workerPhone) return;

            const verifiedResult = await store.dispatch("actions/verifyEmailAndPhone", {
                email: workerEmail,
                phone: workerPhone,
            });

            return verifiedResult;
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, verifyEmailAndPhone };
};
